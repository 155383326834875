import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import th from "./languages/th"
import en from "./languages/en"
import i18next from "i18next"
import HttpApi from "i18next-http-backend"
import ChainedBackend from "i18next-chained-backend"
import ENV, { envConfig } from "env"

const CURRENT_LANG_KEY = "CURRENT_LANG_KEY"
export const getCurrentLang = () => {
  const lang = localStorage.getItem(CURRENT_LANG_KEY)
  if (lang) {
    return lang
  }
  setCurrentLang(envConfig.REACT_APP_DEFAULT_LANG)
  return envConfig.REACT_APP_DEFAULT_LANG
}

export const setCurrentLang = (lang: string | undefined) => {
  if (lang) {
    localStorage.setItem(CURRENT_LANG_KEY, lang)
  }
}

i18next
  .use(initReactI18next)
  .use(ChainedBackend)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: getCurrentLang(),
    fallbackLng: getCurrentLang(),
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    backend: {
      backends: [
        HttpApi, // if a namespace can't be loaded via normal http-backend loadPath, then the inMemoryLocalBackend will try to return the correct resources
      ],
      backendOptions: [
        {
          // loadPath: "http://localhost:20901/translate/{{lng}}",
          loadPath: ENV.HOST + "/translate/{{lng}}",
        },
      ],
    },
  })

export const langs = ["en", "th"]
i18next.loadLanguages(langs).then((props) => {
  i18next.addResourceBundle("en", "translation", en.translation, true, true)
  i18next.addResourceBundle("th", "translation", th.translation, true, true)
})

i18next.on("languageChanged", (lang) => {
  setCurrentLang(lang)
})

export default i18n

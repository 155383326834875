import paths from "routes/paths"
import { isEmpty } from "lodash"
import { Box } from "components"
import T from "components/common/T"
import { TFunction } from "i18next"
import { useLocation } from "react-router-dom"
import { PreviewBenefit } from "constants/enums/preview-benefit"
import withPreventLeaveDirtyForm from "enhancers/withPreventLeaveDirtyForm"
import { ReactComponent as AlertSignIcon } from "assets/icon/alert_sign-icon.svg"
import { compose, withFormik, withHooks, withStores, withTranslation } from "enhancers"

import { BenefitDetailComponent } from "../detail"

export interface BenefitDetailComponentProps {
  t: TFunction
  year: number | string
  name: string
  age: number
  insuranceTypes: any[]
  handleClickBack: () => void
  handleClickEdit: (typeId: string) => void
  availablePoint: number
  usingPoint: number
  isPreview: boolean
}

const enhancer = compose(
  withStores((stores: any) => ({
    selectedBenefitInsurancePlans: stores.benefitInsuranceStore.selectedBenefitInsurancePlans,
    insuranceType: stores.benefitInsuranceStore.insuranceType,
    selectedBenefitInsurancePackage: stores.benefitInsuranceStore.selectedBenefitInsurancePackage,
    benefitInsurance: stores.benefitInsuranceStore.benefitInsurance,
  })),
  withFormik(),
  withTranslation({ prefix: "pages.main.benefit.detail" }),
  withPreventLeaveDirtyForm({
    title: " ",
    children: (
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" mb={4}>
        <AlertSignIcon />
        <Box mt={8}>
          <T variant="Header/16" color="Gray/Primary Text2">
            {"client.pages.main.benefit.detail.notConfirm"}
          </T>
        </Box>
        <Box mt={4}>
          <T variant="Body/14" color="Gray/Secondary Text">
            {"client.pages.main.benefit.detail.notConfirmYet"}
          </T>
          <T variant="Body/14" color="Warning Text">
            {"client.pages.main.benefit.detail.ifYouOut"}
          </T>
        </Box>
      </Box>
    ),
    cancelButtonVariant: "outlined",
    okButtonLabel: (
      <T variant="Header/16" color="White / White" noWrap>
        {"client.pages.main.benefit.detail.exitNotSave"}
      </T>
    ),
    cancelButtonLabel: (
      <T variant="Header/16" color="Green/Primary Text2" noWrap>
        {"client.pages.main.benefit.detail.close"}
      </T>
    ),
    headerCloseButton: true,
    buttonHeight: 34,
  }),
  withHooks(
    (props: any, hooks: any): Omit<BenefitDetailComponentProps, "t"> => {
      const { useCallback, useParams, useMemo, useEffect } = hooks
      const {
        selectedBenefitInsurancePlans,
        initialValues,
        setValues,
        disablePreventLeaveDirtyForm,
        insuranceType: getInsuranceType,
        benefitInsurance,
      } = props
      const { id } = useParams()

      const selectedBenefitInsurancePlansTranslated = selectedBenefitInsurancePlans

      const insuranceTypes = useMemo(() => {
        if (selectedBenefitInsurancePlansTranslated && getInsuranceType) {
          const returnItems: any[] = []

          for (const insuranceType of getInsuranceType) {
            const existed = returnItems.find((type: any) => type.id === insuranceType.id)

            if (!existed) {
              returnItems.push({
                id: insuranceType.id,
                title: insuranceType.name,
                selected: {
                  name: selectedBenefitInsurancePlansTranslated[insuranceType.id]
                    ? selectedBenefitInsurancePlansTranslated[insuranceType.id].masterInsurancePlan.name
                    : "",
                  point: selectedBenefitInsurancePlansTranslated[insuranceType.id]?.point || 0,
                },
              })
            }
          }
          return returnItems
        }
        return []
      }, [selectedBenefitInsurancePlansTranslated, getInsuranceType])

      const handleClickBack = useCallback(() => {
        paths.previewBenefitPath(id).push()
      }, [id])

      const handleClickEdit = useCallback(
        (typeId: string) => {
          disablePreventLeaveDirtyForm()
          paths
            .previewBenefitCustomEditPath(id, {
              insuranceTypeId: typeId,
              masterInsurancePlanId: selectedBenefitInsurancePlans[typeId].id,
            })
            .push()
        },
        [id, disablePreventLeaveDirtyForm, selectedBenefitInsurancePlans],
      )

      const usingPoint = useMemo(
        () =>
          insuranceTypes.reduce((accumulator: any, insuranceType: any) => {
            return accumulator + insuranceType.selected.point
          }, 0),
        [insuranceTypes],
      )

      useEffect(() => {
        if (!isEmpty(initialValues) && selectedBenefitInsurancePlans) setValues(selectedBenefitInsurancePlans)
      }, [selectedBenefitInsurancePlans, setValues, initialValues])

      const { pathname } = useLocation()
      const currentPath = useMemo(() => {
        return pathname.split("/")[1]
      }, [pathname])

      const isPreview = useMemo(() => currentPath === PreviewBenefit.preview, [currentPath])

      return {
        year: benefitInsurance?.year,
        age: benefitInsurance?.age,
        name: `${benefitInsurance?.firstName || ""} ${benefitInsurance?.lastName || ""}`,
        insuranceTypes,
        handleClickBack,
        handleClickEdit,
        availablePoint: benefitInsurance?.maxPoint || 0,
        usingPoint,
        isPreview,
      }
    },
  ),
)

export const PreviewBenefitDetailPage = enhancer(BenefitDetailComponent)

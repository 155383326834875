import { compose, withHooks, withTranslation, withStores } from "enhancers"
import { TFunction } from "i18next"
import { PageContent } from "layouts"
import { BenefitFooter } from "./Footer"
import { AppColor } from "theme/app-color"
import { Divider, Box, Button } from "components"
import styled from "styled-components"
import paths from "routes/paths"
import Typography from "components/common/Typography"
import { ReactComponent as PointCoinIcon } from "assets/icon/point_coin_icon.svg"
import { ReactComponent as ShieldCheckIcon } from "assets/icon/shield_check-icon.svg"
import { ReactComponent as HeartIcon } from "assets/icon/heart-icon.svg"
import { gql } from "@apollo/client"
import { toCurrency } from "utils/helper"
import parse from "html-react-parser"

const BenefitTitle = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${AppColor["Green/Background"]};
  padding: 8px;
`

const Benefit = styled(Box)`
  display: flex;
  flex-direction: column;
  border: 1px solid ${AppColor["Green/Primary Text2"]};
  border-top: 8px solid ${AppColor["Green/Primary Text2"]};
  border-bottom: 8px solid ${AppColor["Green/Primary Text2"]};
  border-radius: 4px;
  margin: 24px 16px 46px 16px;
`

const Title = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-bottom: 1px solid ${AppColor["Green/Primary Text2"]};
  padding: 8px 16px;
`

const Detail = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
`

const PointBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Bottom = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-color: ${AppColor["Green/Background"]};
  padding: 8px 16px;
`

const Insurance = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: LINESeedSansTH;
`

interface ViewDetailBenefitComponentProps {
  t: TFunction
  handleClickBack: () => void
  type: string
  benefitInsurancePlans: [any]
  benefitInsurancePackages: [any]
}

const ViewDetailBenefitComponent = (props: ViewDetailBenefitComponentProps) => (
  <>
    <PageContent
      title={props.t(".title")}
      onBack={props.handleClickBack}
      showActionFooter
      // actionFooter={<BenefitFooter insurances={props.insurances} />}
      childrenPadding="0px"
      // childrenMarginTop="-80px"
      titleBg={AppColor["White / White"]}
      titleColor={AppColor["Text/Black2"]}
      backButtonColor={AppColor["Text/Black2"]}
      type="secondary"
    >
      {props.benefitInsurancePlans?.map((benefitInsurancePlan: any) => (
        <>
          <BenefitTitle>
            <ShieldCheckIcon style={{ marginRight: "8px" }} />
            <Typography color="Text/Black2" variant="h3">
              {benefitInsurancePlan.insurancePlan.masterInsurancePlan.insuranceType.name}
            </Typography>
          </BenefitTitle>
          <Benefit>
            <Title>
              <Typography color="Text/Black2" variant="h5">
                {benefitInsurancePlan.insurancePlan.masterInsurancePlan.nameTh}
              </Typography>
              <PointBox mt={4}>
                <PointCoinIcon width={16} height={16} style={{ marginRight: "8px" }} />
                <Typography variant="Header/20" color="Text/Black2">
                  {props.t(".requirePoint", {
                    point: toCurrency(benefitInsurancePlan.requiredPoint, { minimumFractionDigits: 0 }),
                  })}
                </Typography>
              </PointBox>
            </Title>
            <Detail>
              <Insurance>{parse(benefitInsurancePlan.insurancePlan.masterInsurancePlan.description || "")}</Insurance>
            </Detail>
            <Bottom>
              <HeartIcon style={{ marginRight: "8px" }} />
              <Typography variant="h5" color="Green/Primary Text2">
                {props.t(".select")}
              </Typography>
            </Bottom>
          </Benefit>
        </>
      ))}
      {props.benefitInsurancePackages?.map((benefitInsurancePackage: any) => (
        <>
          <BenefitTitle>
            <ShieldCheckIcon style={{ marginRight: "8px" }} />
            <Typography color="Text/Black2" variant="h3">
              {props.t(".package")}
            </Typography>
          </BenefitTitle>
          <Benefit>
            <Title>
              <Typography color="Text/Black2" variant="h5">
                {benefitInsurancePackage.insurancePackage.masterInsurancePackage.nameTh}
              </Typography>
              <PointBox mt={4}>
                <PointCoinIcon width={16} height={16} style={{ marginRight: "8px" }} />
                <Typography variant="Header/20" color="Text/Black2">
                  {props.t(".requirePoint", {
                    point: toCurrency(benefitInsurancePackage.requiredPoint, { minimumFractionDigits: 0 }),
                  })}
                </Typography>
              </PointBox>
            </Title>
            <Detail>
              {benefitInsurancePackage.insurancePackage.masterInsurancePackage.insurancePackagesInsurancePlans.map(
                (insurancePackagesInsurancePlan: any, index: any) => (
                  <Insurance>{parse(insurancePackagesInsurancePlan.insurance.description || "")}</Insurance>
                ),
              )}
            </Detail>
            <Bottom>
              <HeartIcon style={{ marginRight: "8px" }} />
              <Typography variant="h5" color="Green/Primary Text2">
                {props.t(".select")}
              </Typography>
            </Bottom>
          </Benefit>
        </>
      ))}
      <Box height={50}></Box>
    </PageContent>
  </>
)

const API = {
  GET_BENEFIT_INSURANCE: gql`
    query GET_BENEFIT_INSURANCE($id: String!) {
      benefitInsurance(id: $id) {
        id
        attendeeId
        type
        benefitInsurancePlans {
          id
          requiredPoint
          insurancePlan {
            id
            masterInsurancePlan {
              id
              nameTh
              nameEn
              premium
              remarkTh
              remarkEn
              description
              insuranceType {
                id
                symbol
                nameTh
                nameEn
              }
            }
          }
        }
        benefitInsurancePackages {
          id
          requiredPoint
          insurancePackage {
            id
            masterInsurancePackage {
              id
              nameTh
              nameEn
              insurancePackagesInsurancePlans {
                id
                insurance {
                  id
                  nameTh
                  nameEn
                  premium
                  remarkTh
                  remarkEn
                  description
                }
              }
            }
          }
        }
      }
    }
  `,
}

const enhancer = compose(
  withStores((stores: any) => ({
    currentUser: stores.appStore.currentUser,
  })),
  withTranslation({ prefix: "pages.main.benefit.viewDetail" }),
  withHooks(
    (props: any, hooks: any): Omit<ViewDetailBenefitComponentProps, "t"> => {
      const { currentUser } = props
      const { useCallback, useParams, useQuery, useMemo, useDataTranslation } = hooks
      const { id } = useParams()

      const { loading, data, error } = useQuery(API.GET_BENEFIT_INSURANCE, {
        variables: { id },
        fetchPolicy: "network-only",
      })

      const benefitInsurance = useDataTranslation(data?.benefitInsurance)

      const handleClickBack = useCallback(() => {
        paths.viewBenefitPath(id).push()
      }, [id])

      return {
        handleClickBack,
        type: benefitInsurance?.type,
        benefitInsurancePlans: benefitInsurance?.benefitInsurancePlans,
        benefitInsurancePackages: benefitInsurance?.benefitInsurancePackages,
      }
    },
  ),
)

export const ViewDetailBenefitPage = enhancer(ViewDetailBenefitComponent)

import paths from "routes/paths"
import { TFunction } from "i18next"
import { BenefitComponent } from ".."
import { compose, withHooks, withTranslation, withStores } from "enhancers"
import { EnumMasterBenefitInsuranceType } from "constants/enums/master-benefit-insurance"
import { formatDate } from "utils/helper"
import { isEmpty } from "lodash"

interface BenefitComponentProps {
  t: TFunction
  year: number | string
  lastDate: string
  point: number
  maxPoint: number
  insurances: string[]
  type: EnumMasterBenefitInsuranceType
  handleClickBack: () => void
}

const enhancer = compose(
  withStores((stores: any) => ({
    selectedBenefitInsurancePlans: stores.benefitInsuranceStore.selectedBenefitInsurancePlans,
    selectedBenefitInsurancePackage: stores.benefitInsuranceStore.selectedBenefitInsurancePackage,
    benefitInsurance: stores.benefitInsuranceStore.benefitInsurance,
  })),
  withTranslation({ prefix: "pages.main.benefit.index" }),
  withHooks(
    (props: any, hooks: any): Omit<BenefitComponentProps, "t"> => {
      const { useCallback, useParams, useMemo } = hooks
      const { benefitInsurance } = props
      const { id } = useParams()
      const handleClickBack = useCallback(() => {
        paths.previewBenefitPath(id).push()
      }, [id])

      const lastDate = useMemo(
        () => (!isEmpty(benefitInsurance) ? formatDate(new Date(benefitInsurance.lastDate), "dd/MM/yyyy") : ""),
        [benefitInsurance],
      )

      const displayRemainingPoint = useMemo(() => {
        const point = benefitInsurance?.maxPoint - benefitInsurance?.point
        if (point < 0) return 0
        return point
      }, [benefitInsurance])

      return {
        year: benefitInsurance?.year,
        lastDate: lastDate,
        point: displayRemainingPoint,
        maxPoint: benefitInsurance?.maxPoint,
        insurances: benefitInsurance?.insurances || [],
        type: benefitInsurance?.type,
        handleClickBack,
      }
    },
  ),
)

export const PreviewBenefitPage = enhancer(BenefitComponent)

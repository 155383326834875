import { BenefitDetailComponent, BenefitDetailComponentProps } from "../detail"
import { compose, withHooks, withTranslation, withStores, withFormik } from "enhancers"
import withPreventLeaveDirtyForm from "enhancers/withPreventLeaveDirtyForm"
import paths from "routes/paths"
import { calculateAge } from "utils/helper"
import { isEmpty } from "lodash"
import { Box } from "components"
import { ReactComponent as AlertSignIcon } from "assets/icon/alert_sign-icon.svg"
import T from "components/common/T"
import { PreviewBenefit } from "constants/enums/preview-benefit"
import { useLocation } from "react-router-dom"

const enhancer = compose(
  withStores((stores: any) => ({
    currentUser: stores.appStore.currentUser,
    selectedBenefitInsurancePackage: stores.benefitInsuranceStore.selectedBenefitInsurancePackage,
    benefitInsurance: stores.benefitInsuranceStore.benefitInsurance,
  })),
  withFormik(),
  withTranslation({ prefix: "pages.main.benefit.detail" }),
  withPreventLeaveDirtyForm({
    title: " ",
    children: (
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" mb={4}>
        <AlertSignIcon />
        <Box mt={8}>
          <T variant="Header/16" color="Gray/Primary Text2">
            {"client.pages.main.benefit.detail.notConfirm"}
          </T>
        </Box>
        <Box mt={4}>
          <T variant="Body/14" color="Gray/Secondary Text">
            {"client.pages.main.benefit.detail.notConfirmYet"}
          </T>
          <T variant="Body/14" color="Warning Text">
            {"client.pages.main.benefit.detail.ifYouOut"}
          </T>
        </Box>
      </Box>
    ),
    cancelButtonVariant: "outlined",
    okButtonLabel: (
      <T variant="Header/16" color="White / White" noWrap>
        {"client.pages.main.benefit.detail.exitNotSave"}
      </T>
    ),
    cancelButtonLabel: (
      <T variant="Header/16" color="Green/Primary Text2" noWrap>
        {"client.pages.main.benefit.detail.close"}
      </T>
    ),
    headerCloseButton: true,
    buttonHeight: 34,
  }),
  withHooks(
    (props: any, hooks: any): Omit<BenefitDetailComponentProps, "t"> => {
      const {
        currentUser,
        selectedBenefitInsurancePackage,
        initialValues,
        setValues,
        disablePreventLeaveDirtyForm,
        benefitInsurance,
      } = props
      const { useCallback, useParams, useEffect, useMemo } = hooks
      const { id } = useParams()

      const benefitInsurancePackages = useMemo(() => {
        if (selectedBenefitInsurancePackage) {
          return [
            {
              id: selectedBenefitInsurancePackage?.id,
              title: "Package ประกัน",
              selected: {
                name: selectedBenefitInsurancePackage?.masterInsurancePackage?.name,
                point: selectedBenefitInsurancePackage.point,
              },
            },
          ]
        }
        return []
      }, [selectedBenefitInsurancePackage])

      const age = calculateAge(currentUser?.employee?.birthDate)

      const handleClickBack = useCallback(() => {
        paths.previewBenefitPath(id).push()
      }, [id])

      const handleClickEdit = useCallback(
        (typeId: string) => {
          disablePreventLeaveDirtyForm()
          paths
            .previewBenefitPackageEditPath(id, { masterInsurancePackageId: selectedBenefitInsurancePackage.id })
            .push()
        },
        [id, disablePreventLeaveDirtyForm, selectedBenefitInsurancePackage],
      )

      useEffect(() => {
        if (!isEmpty(initialValues) && selectedBenefitInsurancePackage) {
          setValues(selectedBenefitInsurancePackage)
        }
      }, [setValues, selectedBenefitInsurancePackage, initialValues])

      const { pathname } = useLocation()
      const currentPath = useMemo(() => {
        return pathname.split("/")[1]
      }, [pathname])

      const isPreview = useMemo(() => currentPath === PreviewBenefit.preview, [currentPath])

      return {
        year: benefitInsurance?.year,
        age,
        name: `${benefitInsurance?.firstName || ""} ${benefitInsurance?.lastName || ""}`,
        insuranceTypes: benefitInsurancePackages,
        handleClickBack,
        handleClickEdit,
        availablePoint: benefitInsurance?.maxPoint || 0,
        usingPoint: benefitInsurancePackages[0]?.selected?.point,
        isPreview,
      }
    },
  ),
)

export const PreviewBenefitDetailPackagePage = enhancer(BenefitDetailComponent)

import { compose, withHooks, withTranslation, withStores } from "enhancers"
import { TFunction } from "i18next"
import { PageContent } from "layouts"
import { BenefitFooter } from "./Footer"
import { AppColor } from "theme/app-color"
import { Divider } from "components"
import styled from "styled-components"
import paths from "routes/paths"
import Typography from "components/common/Typography"
import { ReactComponent as PointCoinIcon } from "assets/icon/point_coin_icon.svg"
import { ReactComponent as BenefitMainIcon } from "assets/icon/benefit_main_icon.svg"
import { formatDate, gql } from "utils/helper"
import { EnumMasterBenefitInsuranceType } from "constants/enums/master-benefit-insurance"
import { useTranslation } from "react-i18next"
import { isEmpty } from "lodash"
import benefitInsuranceStore from "stores/benefitInsuranceStore"

const MainCard = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  margin: 24px 16px;
  background-color: ${AppColor["Green/Background"]};
  border-radius: 16px;
`

const PointContainer = styled("div")`
  margin: 16px;
`

const PointCard = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 16px;
  height: 84px;
  background-color: ${AppColor["Green/Background"]};
  border-radius: 16px;
  .point-card-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
  }
  .header-32 {
    margin-left: 8px;
    margin-right: 8px;
    font-size: 32px;
  }
`

const CustomDivider = styled(Divider)`
  height: 8px;
`

interface BenefitComponentProps {
  t: TFunction
  year: number | string
  lastDate: string
  point: number
  maxPoint: number
  insurances: string[]
  type: EnumMasterBenefitInsuranceType
  handleClickBack: () => void
}

export const BenefitComponent = (props: BenefitComponentProps) => (
  <>
    <PageContent
      title={props.t(".title", { year: props.year })}
      onBack={props.handleClickBack}
      showActionFooter
      childrenPadding="0px"
      childrenMarginTop="-80px"
    >
      <MainCard>
        <BenefitMainIcon />
        <Typography variant="h3">{props.t(".canChange", { year: props.year })}</Typography>
        <br />
        <Typography variant="body1" color={AppColor["Gray/Secondary Text"]}>
          {props.t(".upToDate", { date: props.lastDate })}
        </Typography>
      </MainCard>
      <CustomDivider />
      <PointContainer>
        <Typography variant="h3">{props.t(".pointOfYear", { year: props.year })}</Typography>
        <PointCard>
          <div className="point-card-container">
            <PointCoinIcon />
            <Typography isCurrency className="header-32" variant="h1">
              {props.point}
            </Typography>
            <Typography isCurrency variant="body1" color={AppColor["Gray/Placeholder"]}>
              {`/${props.maxPoint || 0} ${props.t(".point")}`}
            </Typography>
          </div>
        </PointCard>
      </PointContainer>
      <CustomDivider />
      <BenefitFooter insurances={props.insurances} type={props.type} />
    </PageContent>
  </>
)

const API = {
  GET_BENEFIT_INSURANCE: gql`
    query GET_BENEFIT_INSURANCE($id: String!) {
      benefitInsurance(id: $id) {
        id
        type
        attendeeId
        attendee {
          enrollmentEndDate
          points
          attendeeGroup {
            point
          }
        }
        type
        benefitInsurancePlans {
          id
          requiredPoint
          insurancePlan {
            id
            masterInsurancePlan {
              id
              nameTh
              nameEn
              premium
              remarkTh
              remarkEn
              description
            }
          }
        }
        benefitInsurancePackages {
          id
          requiredPoint
          insurancePackage {
            id
            masterInsurancePackage {
              id
              nameTh
              nameEn
            }
          }
        }
        year
        endDate
      }
    }
  `,
}

const enhancer = compose(
  withStores((stores: any) => ({
    selectedBenefitInsurancePlans: stores.benefitInsuranceStore.selectedBenefitInsurancePlans,
    selectedBenefitInsurancePackage: stores.benefitInsuranceStore.selectedBenefitInsurancePackage,
  })),
  withTranslation({ prefix: "pages.main.benefit.index" }),
  withHooks(
    (props: any, hooks: any): Omit<BenefitComponentProps, "t"> => {
      const { useCallback, useQuery, useParams, useMemo, useDataTranslation, useEffect } = hooks
      const { selectedBenefitInsurancePlans, selectedBenefitInsurancePackage } = props

      const { id } = useParams()

      const { data } = useQuery(API.GET_BENEFIT_INSURANCE, { variables: { id } })

      const { i18n } = useTranslation()
      const { language } = i18n

      const benefitInsuranceTranslated = useDataTranslation(data?.benefitInsurance)

      const benefitInsurance = useMemo(() => {
        if (benefitInsuranceTranslated) {
          const {
            year,
            endDate,
            attendee,
            type,
            benefitInsurancePlans,
            benefitInsurancePackages,
          } = benefitInsuranceTranslated
          let insurances = []
          let maxPoint = 0
          if (type === EnumMasterBenefitInsuranceType.custom) {
            insurances = benefitInsurancePlans.map((plan: any) => {
              maxPoint += plan.requiredPoint
              return plan.insurancePlan.masterInsurancePlan.name
            })
          } else if (type === EnumMasterBenefitInsuranceType.package) {
            insurances = benefitInsurancePackages.map((plan: any) => {
              maxPoint += plan.requiredPoint
              return plan.insurancePackage.masterInsurancePackage.name
            })
          }
          return {
            year,
            lastDate: formatDate(new Date(attendee.enrollmentEndDate), "dd/MM/yyyy") || "",
            point: attendee.points,
            maxPoint: attendee.points + maxPoint,
            insurances,
            type,
          }
        }
      }, [benefitInsuranceTranslated])

      const handleClickBack = useCallback(() => {
        paths.homePath().push()
      }, [])

      useEffect(() => {
        if (!isEmpty(selectedBenefitInsurancePlans)) {
          benefitInsuranceStore.clearSelectedBenefitInsurancePlans()
        }
      }, [selectedBenefitInsurancePlans])

      useEffect(() => {
        if (!isEmpty(selectedBenefitInsurancePackage)) {
          benefitInsuranceStore.clearSelectedBenefitInsurancePackage()
        }
      }, [selectedBenefitInsurancePackage])

      return {
        year: benefitInsurance?.year,
        lastDate: benefitInsurance?.lastDate,
        point: benefitInsurance?.point,
        maxPoint: benefitInsurance?.maxPoint,
        insurances: benefitInsurance?.insurances || [],
        type: benefitInsurance?.type,
        handleClickBack,
      }
    },
  ),
)

export const BenefitPage = enhancer(BenefitComponent)

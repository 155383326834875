import { TFunction } from "i18next"
import { Box, Modal } from "components"
import { paths, toCurrency } from "utils/helper"
import Typography from "components/common/Typography"
import benefitInsuranceStore from "stores/benefitInsuranceStore"
import { compose, withHooks, withStores, withTranslation } from "enhancers"
import { ReactComponent as EditLineIcon } from "assets/icon/edit_line_icon.svg"
import { ReactComponent as PointCoinIcon } from "assets/icon/point_coin_icon.svg"

import { InsuranceSelectorComponent } from "../InsuranceSelector"

export interface Insurance {
  id: string
  title: string
  point: number
  description: string
}

export interface InsuranceSelectorProps {
  t: TFunction
  handleClickBack: () => void
  handleSelect: (insurance: Insurance) => void
  insurances: Insurance[]
  selected: string
  header: string
}

const enhancer = compose(
  withStores((stores: any) => ({
    selectedBenefitInsurancePlans: stores.benefitInsuranceStore.selectedBenefitInsurancePlans,
    benefitInsuranceRes: stores.benefitInsuranceStore.benefitInsuranceRes,
  })),
  withTranslation({ prefix: "pages.main.benefit.InsuranceSelector" }),
  withHooks(
    (props: any, hooks: any): Omit<InsuranceSelectorProps, "t"> => {
      const { useState, useCallback, useParams, useUrlParam, useEffect, useMemo, useDataTranslation } = hooks
      const { t, selectedBenefitInsurancePlans, benefitInsuranceRes } = props
      const { id } = useParams()
      const { insuranceTypeId, masterInsurancePlanId } = useUrlParam()
      const { insuranceType, masterBenefitInsurances } = benefitInsuranceRes || {}
      const { masterBenefitInsurancePlans } = masterBenefitInsurances?.[0] || {}

      const [selected, setSelected] = useState("")

      const insuranceTypeName = useMemo(() => {
        if (insuranceType) {
          const found = insuranceType.find((type: any) => type.id === insuranceTypeId)
          return found.name
        }
        return ""
      }, [insuranceType, insuranceTypeId])

      const insurances: Insurance[] = useMemo((): Insurance[] => {
        if (masterBenefitInsurancePlans) {
          const result = masterBenefitInsurancePlans
            .filter(({ masterInsurancePlan }: any) => masterInsurancePlan.insuranceTypeId === insuranceTypeId)
            .map(
              ({ masterInsurancePlan }: any): Insurance => ({
                id: masterInsurancePlan.id,
                description: masterInsurancePlan.description,
                point: Number(masterInsurancePlan.premium),
                title: masterInsurancePlan.name,
              }),
            )
          return result
        }
        return []
      }, [masterBenefitInsurancePlans, insuranceTypeId])

      const handleSelect = useCallback(
        (insurance: Insurance) => {
          Modal.open({
            children: (
              <Box display="flex" justifyContent="center" alignItems="center" style={{ flexDirection: "column" }}>
                <EditLineIcon width={50} height={50} style={{ margin: 40 }} />
                <Typography variant="Header/16">{t(".selectInsurancePlan")}</Typography>
                <br />
                <Typography variant="Body/14" align="center">
                  {t(".needToSelect", { name: insurance.title })}
                </Typography>
                <Box display="flex" alignItems="center">
                  <div className="point-container" style={{ display: "flex", alignItems: "center" }}>
                    <PointCoinIcon style={{ marginRight: "8px" }} />
                    <Typography variant="Header/20">{`${toCurrency(insurance.point, { minimumFractionDigits: 0 })} ${t(
                      ".point",
                    )}`}</Typography>
                  </div>
                </Box>
              </Box>
            ),
            onOk: ({ close }: any) => {
              if (masterBenefitInsurancePlans) {
                close()
                const found = masterBenefitInsurancePlans.find(
                  ({ masterInsurancePlan }: any) => masterInsurancePlan.id === insurance.id,
                )
                if (found) {
                  benefitInsuranceStore.setSelectedBenefitInsurancePlan(found.masterInsurancePlan.insuranceTypeId, {
                    id: found.masterInsurancePlan.id,
                    masterInsurancePlan: found.masterInsurancePlan,
                    point: Number(found.masterInsurancePlan.premium),
                  })
                }
              }
            },
            okButtonVariant: "contained",
            okButtonColor: "primary",
            cancelButtonVariant: "outlined",
            cancelButtonColor: "primary",
          })
        },
        [t, masterBenefitInsurancePlans],
      )

      const handleClickBack = useCallback(() => {
        paths.previewBenefitCustomPath(id).push()
      }, [id])

      useEffect(() => {
        if (selectedBenefitInsurancePlans && selectedBenefitInsurancePlans[insuranceTypeId]) {
          setSelected(selectedBenefitInsurancePlans[insuranceTypeId].id)
        } else if (masterBenefitInsurancePlans) {
          const selectInsurancePlan = masterBenefitInsurancePlans.find(
            ({ masterInsurancePlan }: any) => masterInsurancePlan.id === masterInsurancePlanId,
          )
          if (selectInsurancePlan) {
            const { masterInsurancePlan } = selectInsurancePlan
            benefitInsuranceStore.setSelectedBenefitInsurancePlan(selectInsurancePlan.insuranceTypeId, {
              id: masterInsurancePlan.id,
              masterInsurancePlan,
              point: Number(masterInsurancePlan.premium),
            })
          }
        }
      }, [selectedBenefitInsurancePlans, masterInsurancePlanId, masterBenefitInsurancePlans, insuranceTypeId])

      return {
        handleClickBack,
        handleSelect,
        insurances,
        selected,
        header: insuranceTypeName,
      }
    },
  ),
)

export const PreviewInsuranceSelectorPage = enhancer(InsuranceSelectorComponent)

import { Button } from "@material-ui/core"
import { Link } from "react-router-dom"
import { compose, withHooks } from "enhancers"
import { useFormikContext, useMemo } from "hooks"
import { Path } from "routes/paths"
import { isString, isNil } from "lodash"
import classnames from "classnames"

import { ReactComponent as AddIcon } from "assets/icon/add-icon.svg"
import { ReactComponent as TrashIcon } from "assets/icon/trash_icon.svg"
import { ReactComponent as BigTrashIcon } from "assets/icon/big-trash-icon.svg"
import { ReactComponent as DownloadIcon } from "assets/icon/download-icon.svg"
import { ReactComponent as UploadIcon } from "assets/icon/upload-icon.svg"
import { ReactComponent as HistoryIcon } from "assets/icon/history-icon.svg"
import { ReactComponent as ShoppingBag } from "assets/icon/shopping-bag-icon.svg"
import { ReactComponent as InfoIcon } from "assets/icon/info-icon.svg"
import { CSSProperties } from "react"
import typography from "theme/typography"

const ICONS = {
  add: <AddIcon />,
  trash: <TrashIcon />,
  bigTrash: <BigTrashIcon />,
  download: <DownloadIcon />,
  upload: <UploadIcon />,
  history: <HistoryIcon />,
  shoppingBag: <ShoppingBag />,
  info: <InfoIcon />,
}

export const enhancer = compose(
  withHooks((props: any, hooks: any) => {
    const {
      disabled,
      component,
      to,
      startIcon,
      className,
      children,
      newTab,
      preventInvalidSubmit,
      onClick,
      variant,
    } = props

    const { useState, useCallback } = hooks
    const formikBag = props.type === "submit" ? useFormikContext() : undefined
    const [loading, setLoading] = useState(false)

    const customDisabled = useMemo(() => {
      if (formikBag) {
        const { dirty, isValid, isSubmitting } = formikBag
        const enabled = dirty && (preventInvalidSubmit ? isValid : true) && !isSubmitting && !disabled && !loading
        return !enabled
      } else {
        return disabled || loading
      }
    }, [disabled, formikBag, preventInvalidSubmit, loading])

    const customOnClick = useCallback(
      async (event: any) => {
        setLoading(true)
        await onClick(event)
        setLoading(false)
      },
      [onClick],
    )

    const customTo = useMemo(() => {
      return to instanceof Path ? to.toString() : to
    }, [to])

    const customComponent = useMemo(() => {
      return customTo ? Link : component
    }, [component, customTo])

    const customStartIcon = useMemo(() => {
      if (isString(startIcon)) {
        return ICONS[startIcon as keyof typeof ICONS]
      }
      return startIcon
    }, [startIcon])

    const customClassName = useMemo(() => {
      return classnames(className, { "icon-only": isNil(children) })
    }, [className, children])

    const customStyle = useMemo(
      (): CSSProperties => ({
        fontFamily: typography.fontFamily,
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "26px",
        ...props.style,
      }),
      [props],
    )

    return {
      ...props,
      variant,
      onClick: onClick ? customOnClick : undefined,
      className: customClassName,
      disabled: customDisabled,
      component: customComponent,
      to: customTo,
      startIcon: customStartIcon,
      target: newTab ? "_blank" : undefined,
      style: {
        ...customStyle,
      },
    }
  }),
)

const CustomButton = enhancer(Button)

CustomButton.TrashIcon = TrashIcon
CustomButton.InfoIcon = InfoIcon

export default CustomButton

import { BenefitDetailComponent, BenefitDetailComponentProps } from "./detail"
import { compose, withHooks, withTranslation, withStores, withFormik } from "enhancers"
import withPreventLeaveDirtyForm from "enhancers/withPreventLeaveDirtyForm"
import paths from "routes/paths"
import { gql } from "@apollo/client"
import { calculateAge, notifyError, notifySuccess } from "utils/helper"
import { isEmpty } from "lodash"
import { Box, Modal } from "components"
import { ReactComponent as AlertSignIcon } from "assets/icon/alert_sign-icon.svg"
import T from "components/common/T"
import benefitInsuranceStore from "stores/benefitInsuranceStore"
import { PreviewBenefit } from "constants/enums/preview-benefit"
import { useLocation } from "react-router-dom"

const API = {
  GET_BENEFIT_INSURANCE: gql`
    query GET_BENEFIT_INSURANCE($id: String!) {
      benefitInsurance(id: $id) {
        id
        attendeeId
        type
        attendee {
          points
          attendeeGroup {
            point
          }
          employee {
            prefixNameTh
            prefixNameEn
            firstNameTh
            lastNameTh
            firstNameEn
            lastNameEn
            birthDate
          }
        }
        benefitInsurancePackages {
          id
          requiredPoint
          insurancePackage {
            id
            masterInsurancePackage {
              id
              nameTh
              nameEn
              insurancePackagesInsurancePlans {
                id
                insurance {
                  id
                  nameTh
                  nameEn
                  premium
                  remarkTh
                  remarkEn
                  description
                }
              }
            }
          }
        }
        year
        points
      }
    }
  `,
  CHANGE_BENEFIT_INSURANCE_PACKAGE: gql`
    mutation CHANGE_BENEFIT_INSURANCE_PACKAGE(
      $benefitInsuranceId: String!
      $masterInsurancePackages: JSON!
      $points: Float!
      $id: String!
    ) {
      changeBenefitInsurancePackage(
        input: {
          benefitInsuranceId: $benefitInsuranceId
          masterInsurancePackages: $masterInsurancePackages
          points: $points
          id: $id
        }
      ) {
        id
      }
    }
  `,
}

const enhancer = compose(
  withStores((stores: any) => ({
    currentUser: stores.appStore.currentUser,
    selectedBenefitInsurancePackage: stores.benefitInsuranceStore.selectedBenefitInsurancePackage,
  })),
  withFormik(),
  withTranslation({ prefix: "pages.main.benefit.detail" }),
  withPreventLeaveDirtyForm({
    title: " ",
    children: (
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" mb={4}>
        <AlertSignIcon />
        <Box mt={8}>
          <T variant="Header/16" color="Gray/Primary Text2">
            {"client.pages.main.benefit.detail.notConfirm"}
          </T>
        </Box>
        <Box mt={4}>
          <T variant="Body/14" color="Gray/Secondary Text">
            {"client.pages.main.benefit.detail.notConfirmYet"}
          </T>
          <T variant="Body/14" color="Warning Text">
            {"client.pages.main.benefit.detail.ifYouOut"}
          </T>
        </Box>
      </Box>
    ),
    cancelButtonVariant: "outlined",
    okButtonLabel: (
      <T variant="Header/16" color="White / White" noWrap>
        {"client.pages.main.benefit.detail.exitNotSave"}
      </T>
    ),
    cancelButtonLabel: (
      <T variant="Header/16" color="Green/Primary Text2" noWrap>
        {"client.pages.main.benefit.detail.close"}
      </T>
    ),
    headerCloseButton: true,
    buttonHeight: 34,
  }),
  withHooks(
    (props: any, hooks: any): Omit<BenefitDetailComponentProps, "t"> => {
      const {
        t,
        currentUser,
        selectedBenefitInsurancePackage,
        initialValues,
        setInitialValues,
        setValues,
        values,
        disablePreventLeaveDirtyForm,
      } = props
      const { useCallback, useParams, useEffect, useQuery, useMemo, useDataTranslation, useMutation } = hooks
      const { id } = useParams()
      const { loading, data, error } = useQuery(API.GET_BENEFIT_INSURANCE, {
        variables: { id },
        onCompleted: (data: any) => {
          const benefitInsurancePackages = data.benefitInsurance.benefitInsurancePackages[0]
          if (isEmpty(selectedBenefitInsurancePackage)) {
            benefitInsuranceStore.setSelectedBenefitInsurancePackage({
              id: benefitInsurancePackages.insurancePackage.masterInsurancePackage.id,
              masterInsurancePackage: benefitInsurancePackages.insurancePackage.masterInsurancePackage,
              point: benefitInsurancePackages.requiredPoint,
            })
          }
        },
        fetchPolicy: "network-only",
      })

      const [changeBenefitInsurancePackage] = useMutation(API.CHANGE_BENEFIT_INSURANCE_PACKAGE, { skipSetError: true })

      const selectedBenefitInsurancePackageTranslated = useDataTranslation(selectedBenefitInsurancePackage)

      const benefitInsurance = useMemo(() => {
        if (data) {
          return data?.benefitInsurance
        }
        return {}
      }, [data])

      const benefitInsurancePackages = useMemo(() => {
        if (selectedBenefitInsurancePackageTranslated) {
          return [
            {
              id: selectedBenefitInsurancePackageTranslated?.id,
              title: "Package ประกัน",
              selected: {
                name: selectedBenefitInsurancePackageTranslated?.masterInsurancePackage?.name,
                point: selectedBenefitInsurancePackageTranslated.point,
              },
            },
          ]
        }
        return []
      }, [selectedBenefitInsurancePackageTranslated])

      const age = calculateAge(currentUser?.employee?.birthDate)

      const handleClickBack = useCallback(() => {
        paths.benefitManagementPath(id).push()
      }, [id])

      const handleClickEdit = useCallback(
        (typeId: string) => {
          disablePreventLeaveDirtyForm()
          paths
            .benefitPackageManagementEditPath(id, { masterInsurancePackageId: selectedBenefitInsurancePackage.id })
            .push()
        },
        [id, disablePreventLeaveDirtyForm, selectedBenefitInsurancePackage],
      )

      const onSubmit = useCallback(async () => {
        const params = {
          benefitInsuranceId: benefitInsurance.id,
          masterInsurancePackages: selectedBenefitInsurancePackage.masterInsurancePackage,
          points: selectedBenefitInsurancePackage.point,
        }
        try {
          disablePreventLeaveDirtyForm()
          await changeBenefitInsurancePackage({
            variables: {
              ...params,
              id,
            },
          })
          Modal.close()
          notifySuccess(t(".dataSave"))
          handleClickBack()
        } catch (e) {}
      }, [
        changeBenefitInsurancePackage,
        selectedBenefitInsurancePackage,
        benefitInsurance,
        id,
        handleClickBack,
        disablePreventLeaveDirtyForm,
        t,
      ])

      const availablePoint = useMemo(() => {
        if (data) {
          let points = 0
          for (const benefitInsurancePackage of data?.benefitInsurance?.benefitInsurancePackages) {
            points += benefitInsurancePackage.requiredPoint
          }
          return points + data?.benefitInsurance?.attendee.points
        }
      }, [data])

      useEffect(() => {
        if (isEmpty(initialValues) && data) {
          const benefitInsurancePackages = data?.benefitInsurance?.benefitInsurancePackages[0]
          setInitialValues({
            id: benefitInsurancePackages.insurancePackage.masterInsurancePackage.id,
            masterInsurancePackage: benefitInsurancePackages.insurancePackage.masterInsurancePackage,
            point: benefitInsurancePackages.requiredPoint,
          })
        }
      }, [initialValues, data, setInitialValues])

      useEffect(() => {
        if (!isEmpty(initialValues) && selectedBenefitInsurancePackage) {
          setValues(selectedBenefitInsurancePackage)
        }
      }, [setValues, selectedBenefitInsurancePackage, initialValues])

      const { pathname } = useLocation()
      const currentPath = useMemo(() => {
        return pathname.split("/")[1]
      }, [pathname])

      const isPreview = useMemo(() => currentPath === PreviewBenefit.preview, [currentPath])

      return {
        year: benefitInsurance?.year,
        age,
        name: currentUser?.employee?.firstNameTh + " " + currentUser?.employee?.lastNameTh,
        insuranceTypes: benefitInsurancePackages,
        handleClickBack,
        handleClickEdit,
        availablePoint,
        usingPoint: benefitInsurancePackages[0]?.selected?.point,
        onSubmit,
        isPreview,
        benefitInsurance,
        selectedBenefitInsurancePackage,
        id,
      }
    },
  ),
)

export const BenefitDetailPackagePage = enhancer(BenefitDetailComponent)

import { compose, withHooks, withTranslation, withStores } from "enhancers"
import { TFunction } from "i18next"
import { PageContent } from "layouts"
import { BenefitFooter } from "./Footer"
import { AppColor } from "theme/app-color"
import { Divider, Box, Button } from "components"
import styled from "styled-components"
import paths from "routes/paths"
import Typography from "components/common/Typography"
import { ReactComponent as PointCoinIcon } from "assets/icon/point_coin_icon.svg"
import { ReactComponent as UserIcon } from "assets/icon/user.svg"
import { ReactComponent as ShieldCheckIcon } from "assets/icon/shield_check-icon.svg"
import { ReactComponent as DocIcon } from "assets/icon/doc-icon.svg"
import { gql } from "@apollo/client"
import { EnumMasterBenefitInsuranceType } from "constants/enums/master-benefit-insurance"
import { toCurrency } from "utils/helper"

const MainCard = styled("div")`
  display: flex;
  flex-direction: column;
  margin: 24px 16px 0px 16px;
  background-color: ${AppColor["Green/Background"]};
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  padding: 16px 16px 24px 16px;
`

const ViewBox = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 0px 16px 0px 16px;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  background-color: ${AppColor["White / White"]};
  padding: 16px 16px 24px 16px;
`

const UserInfo = styled(Box)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  border-bottom: 1px solid ${AppColor["Gray/Line"]};
  padding-bottom: 24px;
`

const InsuranceDetail = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`

const Benefit = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  border: 1px solid ${AppColor["Green/Primary Text2"]};
  border-top: 8px solid ${AppColor["Green/Primary Text2"]};
  border-radius: 4px;
`

const InsuranceType = styled(Box)`
  display: flex;
  align-items: center;
  background-color: ${AppColor["Green/Background"]};
  border-bottom: 1px solid ${AppColor["Green/Primary Text2"]};
  padding: 8px;
`

const Action = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
`

const DetailButton = styled(Button)`
  color: ${AppColor["Green/Primary Text"]};
  border: none;
  font-size: 14px;
  font-weight: 700;
  padding: 2px 8px 2px 8px;
  height: 26px;
`

const BenefitInsurance = styled(Box)`
  padding: 8px 16px 16px 16px;
`

const InsuranceItems = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
`

const PointBox = styled(Box)`
  display: flex;
  flex-direction: row;
`

const Point = styled(Box)`
  display: flex;
  justify-content: flex-end;
  width: 50px;
`

interface ViewBenefitComponentProps {
  t: TFunction
  handleClickBack: () => void
  name: string
  age: number
  handleViewDetail: () => void
  year: string
  type: string
  benefitInsurancePlans: [any]
  benefitInsurancePackages: [any]
}

const ViewBenefitComponent = (props: ViewBenefitComponentProps) => (
  <>
    <PageContent
      title={props.t(".title", { year: props.year })}
      onBack={props.handleClickBack}
      showActionFooter
      // actionFooter={<BenefitFooter insurances={props.insurances} />}
      childrenPadding="0px"
      childrenMarginTop="-80px"
      bgColor={AppColor["Gray/Background "]}
    >
      <MainCard>
        <Typography color="Text/Black2" variant="h3">
          {props.t(".selected", { year: props.year })}
        </Typography>
        <Box mt={2}>
          <Typography variant="Body/14" color="Gray/Secondary Text">
            {props.t(".view", { year: props.year })}
          </Typography>
        </Box>
      </MainCard>
      <ViewBox>
        <UserInfo>
          <Box>
            <Typography variant="Header/16" color="Text/Black2">
              {props.t(".ownInsurance")}
            </Typography>
            <Box mt={1}>
              <Typography variant="Body/14" color="Green/Primary Text">
                {(props.name || "") + " " + props.t(".years", { year: props.age })}
              </Typography>
            </Box>
          </Box>
          <UserIcon fill={AppColor["Green/Primary Text"]} />
        </UserInfo>
        <InsuranceDetail>
          <Typography color="Gray/Secondary Text" variant="h5">
            {props.t(".insuranceDetail")}
          </Typography>
          <Benefit>
            <InsuranceType>
              <ShieldCheckIcon style={{ marginRight: "8px" }} />
              <Typography variant="h3" color="Green/Primary Text">
                {props.type === EnumMasterBenefitInsuranceType.custom ? props.t(".custom") : props.t(".package")}
              </Typography>
            </InsuranceType>
            <BenefitInsurance>
              {props.benefitInsurancePlans?.map((benefitInsurancePlan: any) => (
                <InsuranceItems>
                  <Typography variant="subtitle2" color="Text/Black2">
                    {benefitInsurancePlan.insurancePlan.masterInsurancePlan.nameTh}
                  </Typography>
                  <PointBox>
                    <PointCoinIcon width={16} height={16} />
                    <Point>
                      <Typography variant="subtitle2" color="Gray/Secondary Text">
                        {toCurrency(benefitInsurancePlan.requiredPoint, { minimumFractionDigits: 0 })}
                      </Typography>
                    </Point>
                  </PointBox>
                </InsuranceItems>
              ))}
              {props.benefitInsurancePackages?.map((benefitInsurancePackage: any) => (
                <InsuranceItems>
                  <Typography variant="subtitle2" color="Text/Black2">
                    {"IPD Basic"}
                  </Typography>
                  <PointBox>
                    <PointCoinIcon width={16} height={16} />
                    <Point>
                      <Typography variant="subtitle2" color="Gray/Secondary Text">
                        {toCurrency(benefitInsurancePackage.requiredPoint, { minimumFractionDigits: 0 })}
                      </Typography>
                    </Point>
                  </PointBox>
                </InsuranceItems>
              ))}
            </BenefitInsurance>
          </Benefit>
        </InsuranceDetail>
        <Action>
          <Box></Box>
          <DetailButton variant="outlined" onClick={props.handleViewDetail}>
            <DocIcon width={16} height={16} fill={AppColor["Green/Primary Text"]} style={{ marginRight: "4px" }} />
            {props.t(".viewDetail")}
          </DetailButton>
        </Action>
      </ViewBox>
    </PageContent>
  </>
)

const API = {
  GET_BENEFIT_INSURANCE: gql`
    query GET_BENEFIT_INSURANCE($id: String!) {
      benefitInsurance(id: $id) {
        id
        attendeeId
        type
        benefitInsurancePlans {
          id
          requiredPoint
          insurancePlan {
            id
            masterInsurancePlan {
              id
              nameTh
              nameEn
              premium
              remarkTh
              remarkEn
              description
            }
          }
        }
        benefitInsurancePackages {
          id
          requiredPoint
          insurancePackage {
            id
            masterInsurancePackage {
              id
              nameTh
              nameEn
            }
          }
        }
        year
      }
    }
  `,
}

const enhancer = compose(
  withStores((stores: any) => ({
    currentUser: stores.appStore.currentUser,
  })),
  withTranslation({ prefix: "pages.main.benefit.view" }),
  withHooks(
    (props: any, hooks: any): Omit<ViewBenefitComponentProps, "t"> => {
      const { currentUser } = props
      const { useCallback, useParams, useQuery, useMemo } = hooks
      const { id } = useParams()

      const { loading, data, error } = useQuery(API.GET_BENEFIT_INSURANCE, {
        variables: { id },
        fetchPolicy: "network-only",
      })

      const benefitInsurance = useMemo(() => {
        if (data) {
          return data?.benefitInsurance
        }
        return {}
      }, [data])

      const getAgeFromBirthdate = useCallback((date: any) => {
        const today = new Date()
        const startDate = new Date(date)
        const todayCount = Math.floor(today.getTime() / (3600 * 24 * 1000))
        const startDateCount = Math.floor(startDate.getTime() / (3600 * 24 * 1000))
        const daysDiff = todayCount - startDateCount
        return Math.floor(daysDiff / 365)
      }, [])

      const handleClickBack = useCallback(() => {
        paths.homePath().push()
      }, [])

      const handleViewDetail = useCallback(() => {
        paths.viewDetailBenefitPath(id).push()
      }, [id])

      const age = getAgeFromBirthdate(currentUser?.employee?.birthDate)

      return {
        handleClickBack,
        name: currentUser?.employee?.firstNameTh + " " + currentUser?.employee?.lastNameTh,
        age,
        handleViewDetail,
        year: benefitInsurance?.year,
        type: benefitInsurance?.type,
        benefitInsurancePlans: benefitInsurance?.benefitInsurancePlans,
        benefitInsurancePackages: benefitInsurance?.benefitInsurancePackages,
      }
    },
  ),
)

export const ViewBenefitPage = enhancer(ViewBenefitComponent)

export enum EnumEmployeeRole {
  BASIC = "BASIC",
  HR_LV1 = "HR_LV1",
  HR_LV2 = "HR_LV2",
}

export enum HR {
  "HR_LV1" = "HR_LV1",
  "HR_LV2" = "HR_LV2",
}

import { ReactComponent as PointCoinIcon } from "assets/icon/point_coin_icon.svg"
import { Box, Button, Divider, Grid, IconButton, Modal } from "components"
import Typography from "components/common/Typography"
import { compose, withHooks, withTranslation } from "enhancers"
import { TFunction, t } from "i18next"
import { get } from "lodash"
import styled, { CSSProperties } from "styled-components"
import { AppColor } from "theme/app-color"
import { gql, toCurrency } from "utils/helper"
import { ConsentModal } from "./Consent"
import ClearIcon from "@material-ui/icons/Clear"
import { ReactComponent as AlertSignIcon } from "assets/icon/alert_sign-icon.svg"
import T from "components/common/T"
import { Input } from "@material-ui/core"
const Container = styled("div")`
  background-color: ${AppColor["Gray/Placeholder"]};
  .content {
    padding: 16px 16px 24px 16px;
  }
  .detail-container {
    border: 1px solid ${AppColor["White / White"]};
    border-radius: 4px;
    padding: 16px;
    margin: 16px 0px;
  }
`

interface FooterSummaryProps {
  t: TFunction
  availablePoint: string
  usingPoint: string
  totalPoint: string
  showModal: () => void
  onSubmit: () => void
  isPreview: boolean
}

const flexStyle: CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
}

const FooterSummaryComponent = ({
  t,
  availablePoint,
  usingPoint,
  totalPoint,
  showModal,
  isPreview,
}: FooterSummaryProps) => (
  <Container>
    <div className="content">
      <Typography color={AppColor["White / White"]} variant="h6">
        {t(".summaryPoint")}
      </Typography>
      <div className="detail-container">
        <Grid container>
          <Grid item xs={8}>
            <Typography color={AppColor["White / White"]} variant="subtitle2">
              {t(".availablePoint")}
            </Typography>
          </Grid>
          <Grid item xs={2} style={{ ...flexStyle, marginBottom: 16 }}>
            <PointCoinIcon width={16} height={16} />
          </Grid>
          <Grid item xs={2} style={{ ...flexStyle, marginBottom: 16 }}>
            <Typography color={AppColor["White / White"]} variant="subtitle2">
              {availablePoint}
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography color={AppColor["White / White"]} variant="subtitle2">
              {t(".usingPoint")}
            </Typography>
          </Grid>
          <Grid item xs={2} style={flexStyle}>
            <PointCoinIcon width={16} height={16} />
          </Grid>
          <Grid item xs={2} style={flexStyle}>
            <Typography color={AppColor["White / White"]} variant="subtitle2">
              {`-${usingPoint}`}
            </Typography>
          </Grid>

          <Divider style={{ width: "100%", marginTop: 16, marginBottom: 16 }} />

          <Grid item xs={8}>
            <Typography color={AppColor["White / White"]} variant="h5">
              {t(".remainingPoint")}
            </Typography>
          </Grid>
          <Grid item xs={2} style={flexStyle}>
            <PointCoinIcon width={16} height={16} />
          </Grid>
          <Grid item xs={2} style={flexStyle}>
            <Typography color={AppColor["White / White"]} variant="h5">
              {totalPoint}
            </Typography>
          </Grid>
        </Grid>
      </div>
      {!isPreview && (
        <Button color="primary" style={{ border: "none" }} onClick={showModal}>
          {t(".confirm")}
        </Button>
      )}
    </div>
  </Container>
)
const API = {
  validatePoints: gql`
    mutation validatePoints(
      $benefitInsuranceId: String
      $masterBenefitInsurancePlan: JSON
      $id: String
      $points: Float
    ) {
      validateAttendeePoints(
        input: {
          benefitInsuranceId: $benefitInsuranceId
          masterBenefitInsurancePlan: $masterBenefitInsurancePlan
          id: $id
          points: $points
        }
      )
    }
  `,
}

const enhancer = compose(
  withTranslation({ prefix: "pages.main.benefit.FooterSummary" }),
  withHooks(
    (props: any, hooks: any): Omit<FooterSummaryProps, "t"> => {
      const { useMutation, useState } = hooks
      const {
        availablePoint,
        usingPoint,
        onSubmit,
        isPreview,
        masterBenefitInsurancePlan,
        benefitInsuranceResTranslated,
        benefitInsurance,
        selectedBenefitInsurancePackage,
        id,
      } = props
      const [validateAttendeePoint, { data }] = useMutation(API.validatePoints, {
        onCompleted: (data: any) => {
          Modal.open({
            children: <ConsentModal onSubmit={onSubmit} />,
            fullWidth: false,
            hideFooter: true,
          })
        },
        skipSetError: true,
        onError: (error: any) => {
          const errors = get(error, "networkError.result.errors")
          const errorsResponse = errors[0].extensions.originalError[0].errorResponse
          const titleError = errorsResponse?.titleError
          const messageError = errorsResponse?.messageError
          const confirmError = errorsResponse?.confirmError
          Modal.alert({
            title: " ",
            children: (
              <>
                <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" mb={4} mt={10}>
                  <AlertSignIcon />
                  <Box mt={8}>
                    <T variant="Header/16" color="Gray/Primary Text2">
                      {titleError}
                    </T>
                  </Box>
                  <Box mt={4}>
                    <T variant="Body/14" color="Gray/Secondary Text">
                      {messageError}
                    </T>
                  </Box>
                </Box>
              </>
            ),
            onClose: handleCloseModal,
            headerCloseButton: true,
            okButtonLabel: (
              <Button color="primary" style={{ border: "none" }}>
                <T>{confirmError}</T>
              </Button>
            ),
          })
        },
      })
      const handleCloseModal = (data: any) => {
        Modal.closeAlertModal()
      }
      const showModal = () => {
        validateAttendeePoint({
          variables: {
            benefitInsuranceId: benefitInsuranceResTranslated?.benefitInsurance?.id ?? benefitInsurance?.id,
            masterBenefitInsurancePlan: masterBenefitInsurancePlan ?? {},
            // Use in Packages
            id,
            points: selectedBenefitInsurancePackage?.point,
          },
        })
      }

      return {
        availablePoint: toCurrency(availablePoint, { minimumFractionDigits: 0 }),
        usingPoint: toCurrency(usingPoint, { minimumFractionDigits: 0 }),
        totalPoint: toCurrency(availablePoint - usingPoint, { minimumFractionDigits: 0 }),
        onSubmit,
        showModal,
        isPreview,
      }
    },
  ),
)

export const FooterSummary = enhancer(FooterSummaryComponent)

import { compose, withHooks } from "enhancers"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { Switch } from "formik-material-ui"

const SwitchInput = (props: any) => (
  <FormControlLabel
    control={<Switch {...props} type="checkbox" checked={props.field.value === true} />}
    label={props.label}
  />
)

const enhancer = compose(
  withHooks((props: any) => {
    return {
      key: props.forceFix ? `${props.field?.value}` : undefined,
      ...props,
    }
  }),
)

export default enhancer(SwitchInput)

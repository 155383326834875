import { compose, withHooks } from "enhancers"
import Alert, { AlertProps } from "@material-ui/lab/Alert"

interface Props extends AlertProps {
  errorMessage: string
}

const ErrorMessage = ({ errorMessage, ...alertProps }: Props) =>
  errorMessage ? (
    <Alert severity="error" {...alertProps}>
      {errorMessage}
    </Alert>
  ) : null

const enhancer = compose(
  withHooks((props: any, hooks: any) => {
    const { name, ...alertProps } = props
    const { useMemo, useFormikContext } = hooks
    const formikBag = useFormikContext()

    const errorMessage = useMemo(() => {
      return formikBag ? formikBag.errors[name] : null
    }, [formikBag, name])

    return {
      errorMessage,
      ...alertProps,
    }
  }),
)

export default enhancer(ErrorMessage)

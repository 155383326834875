import { compose, withHooks, withStores, withTranslation } from "enhancers"
import { PageContent } from "layouts"
import paths from "routes/paths"
import { MaterialIcon } from "components/common/MaterialIcon"
import { Typography, Box } from "components"
import styled from "styled-components"
import { AppColor } from "theme/app-color"
import { useTranslation } from "react-i18next"

const ProfileContainer = styled(Box)`
  display: flex;
  align-items: center;
  padding: 0px 0px 24px 0px;
  border-bottom: 1px solid ${AppColor["Green/Primary Text2"]};
`

const TextContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`

const Menu = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 8px;
  background: ${AppColor["Green/Background"]};
  border-radius: 16px;
`

const MenuIcon = styled(MaterialIcon)`
  font-size: 16px;
  margin-right: 8px;
`

const ProfileComponent = (props: any) => (
  <>
    <PageContent
      title=""
      type="secondary"
      onBack={props.handleClickBack}
      titleBg={AppColor["White / White"]}
      backButtonColor={AppColor["Green/Primary Text2"]}
      childrenPadding="0px"
    >
      <Box padding="0px 16px">
        <ProfileContainer>
          <TextContainer>
            <Typography variant="Header/24" color={AppColor["Gray/Primary Text"]}>
              {props.name}
            </Typography>
            <Typography variant="h5" color={AppColor["Gray/Primary Text"]}>
              {props.t(".age", { age: props.age })}
            </Typography>
          </TextContainer>
        </ProfileContainer>
        <Box mt="40px">
          <Typography variant="h3" color={AppColor["Gray/Primary Text"]}>
            {props.t(".contactInformation")}
          </Typography>
        </Box>
        <Menu mt="24px">
          <Box display="flex" flexDirection="row" alignItems="center">
            <MenuIcon name="MailOutline" htmlColor={AppColor["Gray/Placeholder2"]} />
            <Typography variant="Body/14" color={AppColor["Gray/Placeholder2"]}>
              {props.t(".email")}
            </Typography>
          </Box>
          <Box mt="4px">
            <Typography variant="h3" color={AppColor["Green/Primary Text2"]}>
              {props.email}
            </Typography>
          </Box>
        </Menu>
      </Box>
    </PageContent>
  </>
)

const enhancer = compose(
  withStores((stores: any) => ({
    currentUser: stores.appStore.currentUser,
  })),
  withTranslation({ prefix: "pages.main.profile.index" }),
  withHooks((props: any, hooks: any) => {
    const { useCallback } = hooks
    const { currentUser } = props

    const { i18n } = useTranslation()
    const { language } = i18n

    const getAgeFromBirthdate = useCallback((date: any) => {
      const today = new Date()
      const startDate = new Date(date)
      const todayCount = Math.floor(today.getTime() / (3600 * 24 * 1000))
      const startDateCount = Math.floor(startDate.getTime() / (3600 * 24 * 1000))
      const daysDiff = todayCount - startDateCount
      return Math.floor(daysDiff / 365)
    }, [])

    const name =
      (language === "th" ? currentUser?.employee?.firstNameTh : currentUser?.employee?.firstNameEn || "-") +
      " " +
      (language === "th" ? currentUser?.employee?.lastNameTh : currentUser?.employee?.lastNameEn || "-")
    const age = getAgeFromBirthdate(currentUser?.employee?.birthDate) || "-"
    const email = currentUser?.email || "-"

    const handleClickBack = useCallback(() => {
      paths.accountPath().push()
    }, [])

    return { handleClickBack, name, age, email }
  }),
)

export const ProfilePage = enhancer(ProfileComponent)

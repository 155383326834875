import { isEmpty } from "lodash"
import paths from "routes/paths"
import { Box } from "components"
import { TFunction } from "i18next"
import T from "components/common/T"
import { PageContent } from "layouts"
import styled from "styled-components"
import { AppColor } from "theme/app-color"
import Typography from "components/common/Typography"
import { Button, Divider, Form, Modal } from "components"
import benefitInsuranceStore from "stores/benefitInsuranceStore"
import { ReactComponent as UserIcon } from "assets/icon/user.svg"
import { calculateAge, gql, notifySuccess, toCurrency } from "utils/helper"
import withPreventLeaveDirtyForm from "enhancers/withPreventLeaveDirtyForm"
import { ReactComponent as ShieldCheck } from "assets/icon/shield_check.svg"
import { ReactComponent as EditLineIcon } from "assets/icon/edit_line_icon.svg"
import { ReactComponent as PointCoinIcon } from "assets/icon/point_coin_icon.svg"
import { ReactComponent as AlertSignIcon } from "assets/icon/alert_sign-icon.svg"
import { compose, withFormik, withHooks, withStores, withTranslation } from "enhancers"

import { FooterSummary } from "./FooterSummary"
import { useState } from "react"

const MainCard = styled("div")`
  margin: 24px 16px;
  border-radius: 16px;
  background-color: ${AppColor["White / White"]};
`

const HeaderContainer = styled("div")`
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  padding: 16px;
  background-color: ${AppColor["Green/Background"]};
`

const ContentContainer = styled("div")`
  position: relative;
  padding: 16px;
  margin-bottom: 45px;
`

const InsuranceCard = styled("div")`
  border: 1px solid ${AppColor["Green/Primary Text"]};
  border-top: 8px solid ${AppColor["Green/Primary Text"]};
  border-radius: 4px;
  margin-top: 24px;
  min-height: 80px;
  .header {
    display: flex;
    padding: 10px;
    background-color: ${AppColor["Green/Background"]};
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    .point {
      display: flex;
      align-items: center;
    }
  }
`

const EditContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`

export interface BenefitDetailComponentProps {
  t: TFunction
  year: number | string
  name: string
  age: number
  insuranceTypes: any[]
  handleClickBack: () => void
  handleClickEdit: (typeId: string) => void
  availablePoint: number
  usingPoint: number
  onSubmit?: () => void
  isPreview: boolean
  masterBenefitInsurancePlan?: any
  benefitInsuranceResTranslated?: any
  benefitInsurance?: any
  selectedBenefitInsurancePackage?: any
  id?: any
}

export const BenefitDetailComponent = ({
  t,
  age,
  name,
  year,
  insuranceTypes,
  handleClickBack,
  handleClickEdit,
  availablePoint,
  usingPoint,
  onSubmit,
  isPreview,
  masterBenefitInsurancePlan,
  benefitInsuranceResTranslated,
  benefitInsurance,
  selectedBenefitInsurancePackage,
  id,
}: BenefitDetailComponentProps) => (
  <>
    <Form>
      <PageContent
        title={t(".title", { year })}
        onBack={handleClickBack}
        showActionFooter
        childrenPadding="0px"
        childrenMarginTop="-80px"
        type="primary"
        bgColor={AppColor["Gray/Background "]}
      >
        <MainCard>
          <HeaderContainer>
            <Typography variant="h3">{t(".header", { year })}</Typography>
            <div style={{ height: "8px" }} />
            <Typography variant="body1">{t(".description", { year })}</Typography>
          </HeaderContainer>
          <ContentContainer>
            <UserIcon fill={AppColor["Green/Primary Text"]} style={{ position: "absolute", top: 16, right: 16 }} />
            <Typography variant="h3">{t(".yourInsurance")}</Typography>
            <Typography variant="Body/14" color={AppColor["Green/Primary Text"]}>
              {age ? `${name} (${t(".age", { age })})` : `${name}`}
            </Typography>
            <Divider style={{ marginTop: "16px", marginBottom: "16px" }} />
            <Typography variant="h5">{t(".insuranceDetail")}</Typography>
            {insuranceTypes.map((insuranceType) => (
              <>
                <InsuranceCard>
                  <div className="header">
                    <ShieldCheck style={{ marginRight: "10px" }} fill={AppColor["Green/Primary Text"]} />
                    <Typography variant="h3" color={AppColor["Green/Primary Text"]}>
                      {insuranceType.title}
                    </Typography>
                  </div>
                  <div className="content">
                    <Typography variant="subtitle2">{insuranceType.selected.name}</Typography>
                    <div className="point">
                      <PointCoinIcon width={16} height={16} style={{ marginRight: "16px" }} />
                      <Typography variant="subtitle2" color={AppColor["Green/Primary Text"]}>
                        {toCurrency(insuranceType.selected.point, { minimumFractionDigits: 0 })}
                      </Typography>
                    </div>
                  </div>
                </InsuranceCard>
                <EditContainer>
                  <Button variant="text" onClick={() => handleClickEdit(insuranceType.id)}>
                    <EditLineIcon />
                    <Typography variant="h6" color={AppColor["Green/Primary Text"]}>
                      {t(".edit")}
                    </Typography>
                  </Button>
                </EditContainer>
              </>
            ))}
          </ContentContainer>
        </MainCard>
        <FooterSummary
          availablePoint={availablePoint}
          usingPoint={usingPoint}
          onSubmit={onSubmit}
          isPreview={isPreview}
          masterBenefitInsurancePlan={masterBenefitInsurancePlan}
          benefitInsuranceResTranslated={benefitInsuranceResTranslated}
          benefitInsurance={benefitInsurance}
          selectedBenefitInsurancePackage={selectedBenefitInsurancePackage}
          id={id}
        />
      </PageContent>
    </Form>
  </>
)

const API = {
  GET_BENEFIT_INSURANCE: gql`
    query GET_BENEFIT_INSURANCE($id: String!) {
      benefitInsurance(id: $id) {
        id
        type
        attendeeId
        attendee {
          points
          attendeeGroup {
            point
          }
          employee {
            prefixNameTh
            prefixNameEn
            firstNameTh
            lastNameTh
            firstNameEn
            lastNameEn
            birthDate
          }
        }
        benefitInsurancePlans {
          id
          requiredPoint
          insurancePlan {
            id
            masterInsurancePlan {
              id
              nameTh
              nameEn
              premium
              remarkTh
              remarkEn
              description
              insuranceType {
                id
                symbol
                nameTh
                nameEn
              }
            }
          }
        }
        year
        endDate
      }
    }
  `,
  GET_MASTER_BENEFIT_INSURANCE: gql`
    query GET_MASTER_BENEFIT_INSURANCE($id: String!) {
      masterBenefitInsurance(id: $id) {
        id
        type
        masterBenefitInsurancePlans {
          id
          requiredPoints
          masterInsurancePlan {
            id
            nameTh
            nameEn
            premium
            remarkTh
            remarkEn
            description
            insuranceType {
              id
              symbol
              nameTh
              nameEn
            }
          }
        }
      }
    }
  `,
  CHANGE_BENEFIT_INSURANCE_PLAN: gql`
    mutation CHANGE_BENEFIT_INSURANCE_PLAN($benefitInsuranceId: String!, $masterBenefitInsurancePlan: JSON!) {
      changeBenefitInsurancePlan(
        input: { benefitInsuranceId: $benefitInsuranceId, masterBenefitInsurancePlan: $masterBenefitInsurancePlan }
      ) {
        id
      }
    }
  `,
  FETCH_INSURANCE_TYPES: gql`
    query FETCH_INSURANCE_TYPES {
      insuranceTypes {
        id
        symbol
        nameTh
        nameEn
      }
    }
  `,
}

const enhancer = compose(
  withStores((stores: any) => ({
    selectedBenefitInsurancePlans: stores.benefitInsuranceStore.selectedBenefitInsurancePlans,
  })),
  withFormik(),
  withTranslation({ prefix: "pages.main.benefit.detail" }),
  withPreventLeaveDirtyForm({
    title: " ",
    children: (
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" mb={4}>
        <AlertSignIcon />
        <Box mt={8}>
          <T variant="Header/16" color="Gray/Primary Text2">
            {"client.pages.main.benefit.detail.notConfirm"}
          </T>
        </Box>
        <Box mt={4}>
          <T variant="Body/14" color="Gray/Secondary Text">
            {"client.pages.main.benefit.detail.notConfirmYet"}
          </T>
          <T variant="Body/14" color="Warning Text">
            {"client.pages.main.benefit.detail.ifYouOut"}
          </T>
        </Box>
      </Box>
    ),
    cancelButtonVariant: "outlined",
    okButtonLabel: (
      <T variant="Header/16" color="White / White" noWrap>
        {"client.pages.main.benefit.detail.exitNotSave"}
      </T>
    ),
    cancelButtonLabel: (
      <T variant="Header/16" color="Green/Primary Text2" noWrap>
        {"client.pages.main.benefit.detail.close"}
      </T>
    ),
    headerCloseButton: true,
    buttonHeight: 34,
  }),
  withHooks(
    (props: any, hooks: any): Omit<BenefitDetailComponentProps, "t"> => {
      const { useCallback, useParams, useMutation, useQuery, useMemo, useDataTranslation, useEffect } = hooks
      const {
        t,
        selectedBenefitInsurancePlans,
        initialValues,
        setInitialValues,
        setValues,
        disablePreventLeaveDirtyForm,
        isPreview,
      } = props
      const { id } = useParams()

      const [changeBenefitInsurancePlan] = useMutation(API.CHANGE_BENEFIT_INSURANCE_PLAN, { skipSetError: true })
      const { data: benefitInsuranceRes } = useQuery(API.GET_BENEFIT_INSURANCE, {
        variables: { id },
        onCompleted: (data: any) => {
          const { benefitInsurancePlans } = data?.benefitInsurance
          benefitInsurancePlans.forEach((plan: any) => {
            if (
              !(
                selectedBenefitInsurancePlans &&
                selectedBenefitInsurancePlans[plan.insurancePlan.masterInsurancePlan.insuranceType.id]
              )
            ) {
              benefitInsuranceStore.setSelectedBenefitInsurancePlan(
                plan.insurancePlan.masterInsurancePlan.insuranceType.id,
                {
                  id: plan.insurancePlan.masterInsurancePlan.id,
                  masterInsurancePlan: plan.insurancePlan.masterInsurancePlan,
                  point: plan.requiredPoint,
                },
              )
            }
          })
        },
        fetchPolicy: "network-only",
      })

      const { data: masterBenefitInsuranceRes } = useQuery(API.GET_MASTER_BENEFIT_INSURANCE, { variables: { id } })
      const { data: getInsuranceType } = useQuery(API.FETCH_INSURANCE_TYPES)

      const benefitInsuranceResTranslated = useDataTranslation(benefitInsuranceRes)
      const masterBenefitInsuranceResTranslated = useDataTranslation(masterBenefitInsuranceRes)
      const selectedBenefitInsurancePlansTranslated = useDataTranslation(selectedBenefitInsurancePlans)
      const insuranceTypeTranslated = useDataTranslation(getInsuranceType)

      const insuranceTypes = useMemo(() => {
        if (masterBenefitInsuranceResTranslated && selectedBenefitInsurancePlansTranslated && insuranceTypeTranslated) {
          const { insuranceTypes } = insuranceTypeTranslated
          const returnItems: any[] = []

          for (const insuranceType of insuranceTypes) {
            const existed = returnItems.find((type: any) => type.id === insuranceType.id)

            if (!existed) {
              returnItems.push({
                id: insuranceType.id,
                title: insuranceType.name,
                selected: {
                  name: selectedBenefitInsurancePlansTranslated[insuranceType.id]
                    ? selectedBenefitInsurancePlansTranslated[insuranceType.id].masterInsurancePlan.name
                    : "",
                  point: selectedBenefitInsurancePlansTranslated[insuranceType.id]?.point || 0,
                },
              })
            }
          }
          return returnItems
        }
        return []
      }, [masterBenefitInsuranceResTranslated, selectedBenefitInsurancePlansTranslated, getInsuranceType])

      const currentData = useMemo(() => {
        if (benefitInsuranceResTranslated) {
          const { year, attendee, benefitInsurancePlans } = benefitInsuranceResTranslated.benefitInsurance
          const prefix = attendee.employee.prefixName
          const firstName = attendee.employee.firstName
          const lastName = attendee.employee.lastName
          const age = Number(calculateAge(attendee.employee.birthDate))
          let point = 0
          for (const benefitInsurancePlan of benefitInsurancePlans) {
            point += benefitInsurancePlan.requiredPoint
          }

          return {
            name: `${prefix}${firstName} ${lastName}`,
            age,
            year,
            availablePoint: attendee.points + point,
          }
        }
      }, [benefitInsuranceResTranslated])

      const handleClickBack = useCallback(() => {
        paths.benefitManagementPath(id).push()
      }, [id])

      const handleClickEdit = useCallback(
        (typeId: string) => {
          disablePreventLeaveDirtyForm()
          paths
            .benefitCustomManagementEditPath(id, {
              insuranceTypeId: typeId,
              masterInsurancePlanId: selectedBenefitInsurancePlans[typeId].id,
            })
            .push()
        },
        [id, disablePreventLeaveDirtyForm, selectedBenefitInsurancePlans],
      )
      const masterBenefitInsurancePlan = useMemo(() => {
        if (selectedBenefitInsurancePlans) {
          const selectedList = Object.keys(selectedBenefitInsurancePlans).map(
            (key) => selectedBenefitInsurancePlans[key],
          )
          return selectedList.map((selected) => ({
            ...selected.masterInsurancePlan,
            requiredPoints: selected.point,
          }))
        } else {
          return undefined
        }
      }, [selectedBenefitInsurancePlans])
      const onSubmit = useCallback(async () => {
        const selectedList = Object.keys(selectedBenefitInsurancePlans).map((key) => selectedBenefitInsurancePlans[key])
        try {
          disablePreventLeaveDirtyForm()
          await changeBenefitInsurancePlan({
            variables: {
              benefitInsuranceId: benefitInsuranceResTranslated.benefitInsurance.id,
              masterBenefitInsurancePlan: selectedList.map((selected) => ({
                ...selected.masterInsurancePlan,
                requiredPoints: selected.point,
              })),
              id,
            },
          })
          Modal.close()
          notifySuccess(t(".dataSave"))
          handleClickBack()
        } catch (e) {}
      }, [selectedBenefitInsurancePlans, benefitInsuranceResTranslated, changeBenefitInsurancePlan, id])

      const usingPoint = useMemo(
        () =>
          insuranceTypes.reduce((accumulator: any, insuranceType: any) => {
            return accumulator + insuranceType.selected.point
          }, 0),
        [insuranceTypes],
      )

      useEffect(() => {
        if (isEmpty(initialValues) && benefitInsuranceRes) {
          const { benefitInsurancePlans } = benefitInsuranceRes?.benefitInsurance
          let initialValues: any = {}
          benefitInsurancePlans.forEach((plan: any) => {
            initialValues[plan.insurancePlan.masterInsurancePlan.insuranceType.id] = {
              id: plan.insurancePlan.masterInsurancePlan.id,
              masterInsurancePlan: plan.insurancePlan.masterInsurancePlan,
              point: plan.requiredPoint,
            }
          })
          setInitialValues(initialValues)
        }
      }, [benefitInsuranceRes, setInitialValues, initialValues])

      useEffect(() => {
        if (!isEmpty(initialValues) && selectedBenefitInsurancePlans) setValues(selectedBenefitInsurancePlans)
      }, [selectedBenefitInsurancePlans, setValues, initialValues])

      return {
        year: currentData?.year,
        age: currentData?.age,
        name: currentData?.name,
        insuranceTypes,
        handleClickBack,
        handleClickEdit,
        availablePoint: currentData?.availablePoint || 0,
        usingPoint,
        onSubmit,
        isPreview,
        masterBenefitInsurancePlan,
        benefitInsuranceResTranslated,
      }
    },
  ),
)

export const BenefitDetailPage = enhancer(BenefitDetailComponent)

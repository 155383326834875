import { compose, withHooks, withTranslation } from "enhancers"
import { PageContent } from "layouts"
import paths from "routes/paths"
import { MaterialIcon } from "components/common/MaterialIcon"
import { Typography, Box, IconButton } from "components"
import styled from "styled-components"
import { AppColor } from "theme/app-color"
import { Drawer } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { setCurrentLang } from "common/i18next"
import { notifySuccess } from "utils/helper"

const Menu = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0px;
  background: ${AppColor["White / White"]};
  border-bottom: 1px solid ${AppColor["Gray/Line"]};
`

const MenuIcon = styled(MaterialIcon)`
  font-size: 16px;
  margin-left: 4px;
`

const TitleContainer = styled(Box)`
  display: flex;
  align-items: center;
  padding: 0px 0px 24px 0px;
  border-bottom: 1px solid ${AppColor["Green/Primary Text2"]};
`

const LanguageMenu = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0px;
  background: ${AppColor["White / White"]};
`

const CheckIcon = styled(MaterialIcon)`
  font-size: 24px;
`

const SettingComponent = (props: any) => (
  <>
    <PageContent
      title=""
      type="secondary"
      onBack={props.handleClickBack}
      titleBg={AppColor["White / White"]}
      backButtonColor={AppColor["Green/Primary Text2"]}
      childrenPadding="0px"
    >
      <Box padding="0px 16px">
        <TitleContainer>
          <Typography variant="Header/24" color={AppColor["Gray/Primary Text"]}>
            {props.t(".title")}
          </Typography>
        </TitleContainer>
        <Box mt="40px">
          <Typography variant="h3" color={AppColor["Gray/Primary Text"]}>
            {props.t(".displaySetting")}
          </Typography>
        </Box>
        <Menu mt="16px" onClick={props.openLanguageSetting}>
          <Typography variant="h5" color={AppColor["Gray/Primary Text"]}>
            {props.t(".language")}
          </Typography>
          <Box display="flex" alignItems="center">
            <Typography variant="h3" color={AppColor["Green/Primary Text2"]}>
              {props.t(".currentLanguage")}
            </Typography>
            <MenuIcon name="ExpandMore" htmlColor={AppColor["Green/Primary Text2"]} />
          </Box>
        </Menu>
      </Box>
    </PageContent>
    <Drawer
      className="languageSetting"
      anchor="bottom"
      open={props.showLanguageSetting}
      onClose={props.onCloseLanguageSetting}
    >
      <Box display="flex" padding="16px 16px 24px 16px" flexDirection="column">
        <Box display="flex" justifyContent="space-between">
          <Box height="24px" width="24px"></Box>
          <Typography variant="h3" color={AppColor["Gray/Primary Text"]}>
            {props.t(".languageOption")}
          </Typography>
          <IconButton style={{ padding: "0px" }} onClick={props.handleCloseDrawer}>
            <MaterialIcon name="CloseOutlined" style={{ fontSize: "24px" }} htmlColor={AppColor["Gray/Primary Text"]} />
          </IconButton>
        </Box>
        <LanguageMenu
          onClick={() => props.handleChangeLanguage("th")}
          mt="16px"
          style={{ borderBottom: `1px solid ${AppColor["Gray/Line"]}` }}
        >
          <Typography variant="h5" color={AppColor["Gray/Primary Text"]}>
            {props.t(".thai")}
          </Typography>
          {props.language === "th" && <CheckIcon name="Check" htmlColor={AppColor["Green/Primary Text2"]} />}
        </LanguageMenu>
        <LanguageMenu onClick={() => props.handleChangeLanguage("en")}>
          <Typography variant="h5" color={AppColor["Gray/Primary Text"]}>
            {props.t(".english")}
          </Typography>
          {props.language === "en" && <CheckIcon name="Check" htmlColor={AppColor["Green/Primary Text2"]} />}
        </LanguageMenu>
      </Box>
    </Drawer>
  </>
)

const enhancer = compose(
  withTranslation({ prefix: "pages.main.setting.index" }),
  withHooks((props: any, hooks: any) => {
    const { t } = props
    const { useCallback, useState } = hooks
    const { i18n } = useTranslation()
    const { language, changeLanguage } = i18n

    const [showLanguageSetting, setShowLanguageSetting] = useState(false)

    const openLanguageSetting = useCallback(() => {
      setShowLanguageSetting(true)
    }, [setShowLanguageSetting])

    const handleCloseDrawer = useCallback(() => {
      setShowLanguageSetting(false)
    }, [setShowLanguageSetting])

    const handleClickBack = useCallback(() => {
      paths.accountPath().push()
    }, [])

    const handleChangeLanguage = useCallback(
      (lang: any) => {
        changeLanguage(lang)
        setCurrentLang(lang)
        setShowLanguageSetting(false)
        notifySuccess(t(".dataSave"))
      },
      [changeLanguage, setShowLanguageSetting, t],
    )

    return {
      handleClickBack,
      showLanguageSetting,
      openLanguageSetting,
      handleCloseDrawer,
      language,
      handleChangeLanguage,
    }
  }),
)

export const SettingPage = enhancer(SettingComponent)

import { ReactComponent as WarningIcon } from "assets/icon/warning_rounded_outlined.svg"
import { ReactComponent as EmptyImage } from "assets/image/empty_insurance_image.svg"
import { Box } from "components"
import { MaterialIcon } from "components/common/MaterialIcon"
import Typography from "components/common/Typography"
import { compose, withHooks, withTranslation } from "enhancers"
import { TFunction } from "i18next"
import styled from "styled-components"
import { AppColor } from "theme/app-color"
import { formatPhoneNumber, gql } from "utils/helper"

const Menu = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 8px;
  background: ${AppColor["Green/Background"]};
  border-radius: 16px;
`

const MenuIcon = styled(MaterialIcon)`
  font-size: 16px;
  margin-right: 4px;
`

const Container = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  filter: drop-shadow(0px 4px 8px rgba(43, 43, 43, 0.1));
`

const Content = styled("div")`
  position: relative;
  background-color: ${AppColor["White / White"]};
  width: 300px;
  border-radius: 16px;
`

const Header = styled("div")`
  width: 100%;
  height: 36px;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  background-color: ${AppColor["Warning Background"]};
`

const Image = styled("div")`
  width: auto;
  height: 220px;
  margin: 16px 20px 40px 20px;
`

export type EmptyInsuranceItemProps = {
  setting: any

  t: TFunction
  handleClickButton: () => void
}

const EmptyInsuranceItemComponent = (props: EmptyInsuranceItemProps) => (
  <Container>
    <Content>
      <Header>
        <div style={{ display: "flex", alignItems: "center", padding: "8px", marginRight: 8 }}>
          <WarningIcon width={20} height={20} style={{ marginRight: 8 }} fill={AppColor["Warning Text"]} />

          <Typography variant="subtitle2">{props.t(".title")}</Typography>
        </div>
      </Header>
      <div style={{ padding: "12px", textAlign: "center" }}>
        <Image>
          <EmptyImage />
        </Image>
        <Typography variant="Header/16">{props.t(".description")}</Typography>
        <br />
        <Typography variant="Body/14">{props.t(".contact")}</Typography>
      </div>
      <div style={{ padding: "0px 12px 12px 12px", marginTop: "-4px" }}>
        <Menu>
          <Box display="flex" flexDirection="row" alignItems="center">
            <MenuIcon name="PhoneInTalk" htmlColor={AppColor["Gray/Placeholder2"]} />
            <Typography variant="Body/14" color={AppColor["Gray/Placeholder2"]}>
              {props.t(".phone")}
            </Typography>
          </Box>
          <Box mt="4px">
            <Typography variant="h3" color={AppColor["Green/Primary Text2"]}>
              {formatPhoneNumber(props.setting?.companyPhoneNumber) || "-"}
            </Typography>
          </Box>
        </Menu>
        <Menu mt="8px">
          <Box display="flex" flexDirection="row" alignItems="center">
            <MenuIcon name="MailOutline" htmlColor={AppColor["Gray/Placeholder2"]} />
            <Typography variant="Body/14" color={AppColor["Gray/Placeholder2"]}>
              {props.t(".email")}
            </Typography>
          </Box>
          <Box mt="4px">
            <Typography variant="h3" color={AppColor["Green/Primary Text2"]}>
              {props.setting?.companyEmail || "-"}
            </Typography>
          </Box>
        </Menu>
      </div>
    </Content>
  </Container>
)

const API = {
  GET_SETTING: gql`
    query GET_SETTING {
      getSetting {
        id
        companyEmail
        companyPhoneNumber
      }
    }
  `,
}

const enhancer = compose(
  withTranslation({ prefix: "pages.main.home.EmptyInsuranceItem" }),
  withHooks((props: any, hooks: any) => {
    const { useQuery, useMemo } = hooks

    const { loading, data, error } = useQuery(API.GET_SETTING, { fetchPolicy: "network-only" })

    const setting = useMemo(() => {
      if (loading) {
        return null
      }
      if (error) {
        return null
      }
      return data.getSetting
    }, [loading, data, error])

    return { setting }
  }),
)

export const EmptyInsuranceItem = enhancer(EmptyInsuranceItemComponent)

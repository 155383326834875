import { connect } from "react-redux"
import deepmerge from "deepmerge"

import appStore from "stores/appStore"
import authStore from "stores/authStore"
import BenefitInsuranceStore from "stores/benefitInsuranceStore"

const storeMapping = {
  appStore,
  authStore,
  BenefitInsuranceStore,
}

const withStores = (mapStoresToProps) =>
  connect((state, props) => {
    const stores = deepmerge(storeMapping, state.data, { clone: false })
    return mapStoresToProps(stores, props)
  })

export default withStores

import React, { DetailedHTMLProps, HTMLAttributes, useMemo } from "react"
import { compose, withHooks } from "enhancers"
import classnames from "classnames"

interface IconComponentProps {
  className: string
  style: React.CSSProperties
}

const IconComponent = (props: IconComponentProps) => <i className={props.className} style={props.style}></i>

const enhancer = compose(
  withHooks((props: any) => {
    const { className, name, spin, style } = props

    const customClassName = useMemo(() => {
      return classnames("fa", {
        [`fa-${name}`]: !!name,
        "fa-spin": spin,
        [className]: className,
      })
    }, [className, name, spin])

    return {
      className: customClassName,
      style,
    }
  }),
)

const FontAwesomeIcon = enhancer(IconComponent)

export default FontAwesomeIcon

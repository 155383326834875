import { useEffect } from "react"
import { compose, mapProps } from "recompose"
import * as hooks from "hooks"
import { cloneDeep, forEach, has, isArray, isObject, set, upperFirst } from "lodash"
import { getCurrentLang, langs } from "common/i18next/index"

const withHooks = (mapHooksToProps) => {
  const enhancer = compose(
    mapProps((props) => {
      const newProps = mapHooksToProps(props, {
        ...hooks,
        useHandleSubmit(handleSubmit, dependencies) {
          useEffect(() => {
            props.setPropsToFormikBag({
              handleSubmit,
            })
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, dependencies)
        },
        usePropsToFormikBag(mapPropsToFormikBag, dependencies) {
          useEffect(() => {
            if (props.setPropsToFormikBag && mapPropsToFormikBag) {
              props.setPropsToFormikBag(mapPropsToFormikBag())
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [props.setPropsToFormikBag, ...dependencies])
        },
        useDebug() {
          hooks.useDebug(props)
        },
        useMutation(api, mutationProps) {
          return hooks.useCustomMutation(api, props, mutationProps)
        },
        useDataTranslation(data, options) {
          return hooks.useMemo(() => {
            const newData = cloneDeep(data)
            dataTranslation(newData, options)
            return newData
          }, [data, getCurrentLang()])
        },
      })
      if (props.t) set(newProps, "t", props.t)
      return newProps
    }),
  )
  return enhancer
}

export const dataTranslation = (data, options) => {
  if (isArray(data)) {
    for (const d of data) {
      dataTranslation(d, options)
    }
  } else if (isObject(data)) {
    forEach(data, (value, key) => {
      if (isArray(value) || isObject(value)) {
        dataTranslation(value, options)
        return
      }

      if (/(Th)+$/g.test(key)) {
        const keyWithoutLang = key.substring(0, key.length - 2)
        forEach(langs.slice(1), (lang) => {
          const newKey = keyWithoutLang + upperFirst(lang)

          if (has(data, newKey) && (!has(data, keyWithoutLang) || options.force)) {
            const accessKey = keyWithoutLang + upperFirst(getCurrentLang())
            set(data, keyWithoutLang, data[accessKey])
          } else if (has(data, keyWithoutLang)) {
            throw new Error("Duplicate key when generate transaction data")
          }
        })
      }
    })
  }
}

export default withHooks

import { compose, withHooks } from "enhancers"
import { FormControlLabel, Typography } from "@material-ui/core"
import { Checkbox as FormikMuiCheckbox, CheckboxProps } from "formik-material-ui"

import { ReactComponent as CheckboxOutlineIcon } from "assets/icon/checkbox_outline.svg"
import { ReactComponent as CheckboxOutlineCheckedIcon } from "assets/icon/checkbox_outline_checked.svg"

interface CheckboxItemProps extends CheckboxProps {
  label: string
}

const Checkbox = ({ className, label, ...checkboxProps }: CheckboxItemProps) => (
  <FormControlLabel
    className={className}
    control={
      <FormikMuiCheckbox
        icon={<CheckboxOutlineIcon />}
        checkedIcon={<CheckboxOutlineCheckedIcon />}
        color="primary"
        size="small"
        indeterminate={false}
        style={{ marginLeft: 5 }}
        {...checkboxProps}
      />
    }
    label={
      <Typography variant="caption" style={{ fontSize: 14 }} align="center">
        {label}
      </Typography>
    }
  />
)

const enhancer = compose(
  withHooks((props: any) => {
    return { ...props }
  }),
)

export default enhancer(Checkbox)

import { SvgIconProps } from "@material-ui/core"
import * as MuiIcon from "@material-ui/icons"
import { t } from "i18next"
import React from "react"

export type MuiIconCollections = keyof typeof MuiIcon

interface CustomIconProps extends SvgIconProps {
  name?: MuiIconCollections
}

export const MaterialIcon = (props: CustomIconProps) => {
  const { name, ...rest } = props

  if (MuiIcon[name as MuiIconCollections])
    return React.createElement(MuiIcon[name as MuiIconCollections], {
      ...rest,
    })
  else return <>{t("iconNotFound")}</>
}
